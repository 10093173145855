import request from "../utils/request";

export function getNhanVien(params) {
  return request({
    url: "nhanvien",
    method: "get",
    params,
  });
}

export function addNhanVien(data) {
  return request({
    url: "nhanvien",
    method: "post",
    data,
  });
}

export function editNhanVien(data) {
  return request({
    url: "nhanvien",
    method: "put",
    data,
  });
}

export function showNhanVien(params) {
  return request({
    url: "showNhanVien",
    method: "get",
    params,
  });
}

export function getNhanVienKinhDoanh(params) {
  return request({
    url: "getNhanVienKinhDoanh",
    method: "get",
    params,
  });
}
export function getNhanVienHauCan(params) {
  return request({
    url: "getNhanVienHauCan",
    method: "get",
    params,
  });
}
