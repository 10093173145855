import { AApi } from "@/service/AApi";
const API = new AApi(
  {},
  {
    getReportCustomer: {
      async request(params) {
        return this.sdk({
          url: `thiphan`,
          method: "get",
          params
        });
      }
    },
    exportReportCustomer: {
      async request(params) {
        return this.sdk({
          url: "thiphan-export",
          responseType: 'blob',
          params,
        });
      }
    },
    // report request-compailt

    getReportComplaint: {
      async request(params) {
        return this.sdk({
          url: "khieunai-yeucau",
          method: "get",
          params,
        });
      }
    },

    exportCompailt: {
      async request(params) {
        return this.sdk({
          url: "khieunaiyeucau-export",
          responseType: 'blob',
          params,
        });
      }
    },

    getDeclationBill: {
      async request(params) {
        return this.sdk({
          url: "chungtu-huyhang",
          method: "get",
          params,
        });
      }
    },
    // Chung tu - huy hang  chungtu-huyhang
    // khach hang trong tam khachhangtrongtam-export
    getReportCustomerImportan: {
      async request(params) {
        return this.sdk({
          url: `khachhangtrongtam`,
          method: "get",
          params,
        });
      }
    },
    exportCustomerImportan: {
      async request(params) {
        return this.sdk({
          url: "khachhangtrongtam-export",
          responseType: 'blob',
          params,
        });
      }
    },
    exportDeclation: {
      async request(params) {
        return this.sdk({
          url: "chungtu-huyhang-export",
          responseType: 'blob',
          params,
        });
      }
    },
    reportBusiness: {
      async request() {
        return this.sdk({
          url: "kinhdoanh-khachhang",
          method: 'get',
        });
      }
    },
    reportBusinessResearch: {
      async request(params) {
        return this.sdk({
          url: "kinhdoanh-khachhang-reseach",
          method: 'get',
          params: params
        });
      }
    },

    // bao cao ket qua kinh doanh 
  }
);
  export default API;